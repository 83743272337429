import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import React from "react";
import {Link} from "react-scroll";

const Questions = ({ formStyle, setSelectedPackage, isSendOfferEmail, setIsSendOfferEmail }) => {
    // Van e pultnál kiszolgálás?
    const [counterServiceAvailable, setCounterServiceAvailable] = React.useState(true);

    // Van a asztalnál felszolgálás?
    const [tableServiceAvailable, setTableServiceAvailable] = React.useState(true);

    // Van a házhozszállítás?
    const [deliveryAvailable, setDeliveryAvailable] = React.useState(true);

    // Van a Foodora vagy Wolt kiszállítás?
    const [foodoraOrWoltDeliveryAvailable, setFoodoraOrWoltDeliveryAvailable] = React.useState(true);

    // Van e saját futár és saját webshop?
    const [restaurantHasOwnDeliveryServiceAndWebshop, setRestaurantHasOwnDeliveryServiceAndWebshop] = React.useState(true);

    const handleSwitchChange = (name, e) => {
        const value = e.target.checked
        switch (name) {
            case 'counterServiceAvailable':
                setCounterServiceAvailable(value);
                break;
            case 'tableServiceAvailable':
                setTableServiceAvailable(value);
                break;
            case 'deliveryAvailable':
                setDeliveryAvailable(value);
                break;
            case 'foodoraOrWoltDeliveryAvailable':
                setFoodoraOrWoltDeliveryAvailable(value);
                break;
            case 'restaurantHasOwnDeliveryServiceAndWebshop':
                setRestaurantHasOwnDeliveryServiceAndWebshop(value);
                break;
            default:
                break;
        }
    };

    const onSubmit = () => {
        if (!counterServiceAvailable && !tableServiceAvailable && !deliveryAvailable && !foodoraOrWoltDeliveryAvailable && !restaurantHasOwnDeliveryServiceAndWebshop)
            return setSelectedPackage('')
        if (counterServiceAvailable && tableServiceAvailable && deliveryAvailable && foodoraOrWoltDeliveryAvailable && restaurantHasOwnDeliveryServiceAndWebshop)
            return setSelectedPackage('Prémium')
        if (!tableServiceAvailable && !deliveryAvailable)
            return setSelectedPackage('Mini')
        if (!tableServiceAvailable || !deliveryAvailable)
            return setSelectedPackage('Alap')
        return setSelectedPackage('Alap')
    };

    React.useEffect(() => {
        onSubmit()
    }, [counterServiceAvailable, tableServiceAvailable, deliveryAvailable, foodoraOrWoltDeliveryAvailable, restaurantHasOwnDeliveryServiceAndWebshop])

    const lineStyles = {
        height: '2px',
        width: '40px',
        borderRadius: '2px',
        background: 'rgba(186,192,244,0.64)',
        marginLeft: '25px'
    }
    return (
        <form className={`${formStyle} col-md-8 col-12 p-0`} action=""
            onSubmit={(e) => {
                e.preventDefault()
                setIsSendOfferEmail(true)
            }}
            style={{ margin: '0 auto' }}
        >
            <center className="pre-title" style={{ opacity: '.6' }}>
                Jelölje be mely állítások igazak
            </center>
            <ul className={"container d-flex flex-column justify-content-center m-0"}>
                <li className="row m-3 align-items-center">
                    <strong className="col" style={{ fontSize: '1.75rem', whiteSpace: 'nowrap' }}>
                        Van pultnál kiszolgálás?
                    </strong>
                    <div className="col d-flex justify-content-end">
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={counterServiceAvailable}
                                    onChange={(e) => handleSwitchChange('counterServiceAvailable', e)}
                                />
                            }
                        />
                    </div>
                </li>
                <div className="line" style={lineStyles}></div>
                <li className="row m-3 align-items-center">
                    <strong className="col" style={{ fontSize: '1.75rem', whiteSpace: 'nowrap' }}>
                        Van asztalnál felszolgálás?
                    </strong>
                    <div className="col d-flex justify-content-end">
                        <FormControlLabel
                            control={
                                <Switch checked={tableServiceAvailable}
                                    onChange={(e) => handleSwitchChange('tableServiceAvailable', e)} />
                            }
                        />
                    </div>
                </li>
                <div className="line" style={lineStyles}></div>
                <li className="row m-3 align-items-center">
                    <strong className="col" style={{ fontSize: '1.75rem', whiteSpace: 'nowrap' }}>
                        Van házhozszállítás?
                    </strong>
                    <div className="col d-flex justify-content-end">
                        <FormControlLabel
                            control={
                                <Switch checked={deliveryAvailable}
                                    onChange={(e) => handleSwitchChange('deliveryAvailable', e)} />
                            }
                        />
                    </div>
                </li>
                <div className="line" style={lineStyles}></div>
                <li className="row m-3 align-items-center">
                    <strong className="col" style={{ fontSize: '1.75rem', whiteSpace: 'nowrap' }}>
                        Van Foodora vagy Wolt kiszállítás?
                    </strong>
                    <div className="col d-flex justify-content-end">
                        <FormControlLabel
                            control={
                                <Switch checked={foodoraOrWoltDeliveryAvailable}
                                    onChange={(e) => handleSwitchChange('foodoraOrWoltDeliveryAvailable', e)} />
                            }
                        />
                    </div>
                </li>
                <div className="line" style={lineStyles}></div>
                <li className="row m-3 align-items-center">
                    <strong className="col" style={{ fontSize: '1.75rem', whiteSpace: 'nowrap' }}>
                        Van saját futár és saját webshop?
                    </strong>
                    <div className="col d-flex justify-content-end">
                        <FormControlLabel
                            control={
                                <Switch checked={restaurantHasOwnDeliveryServiceAndWebshop}
                                    onChange={(e) => handleSwitchChange('restaurantHasOwnDeliveryServiceAndWebshop', e)} />
                            }
                        />
                    </div>
                </li>
            </ul>
            <br />
            {false && <div className="row g-5 mb-3">
                <div className="col-lg-12">
                    <div className="section-title text-center">
                        <small className="pre-title"
                            onClick={() => setSelectedPackage('normal')}
                            style={{ fontSize: '1.25rem', cursor: 'pointer' }}>
                            Összes csomag megtekintése
                        </small>
                    </div>
                </div>
            </div>}

            <div className="col-lg-12 d-flex justify-content-center">
                <button className="rn-btn edu-btn w-50"
                        type="submit"
                        onClick={onSubmit}
                >
                    <span>Ajánlatkérés</span><i className="icon-arrow-right-line-right"></i>
                </button>
            </div>
        </form>
    )
}

export default Questions