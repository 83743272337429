import React from 'react';
import { Link } from 'react-router-dom';

const EventOne = (props) => {
    const {data, onSelect} = props;
    return (
        <div className={`edu-event event-list`} style={{cursor: 'pointer'}} onClick={() => onSelect(data)}>
            <div className="inner">
                <div className="content" style={{padding: '10px 0px 10px 0px'}}>
                    <div className="content-left">
                        <h5 className="title" style={{fontSize: '1.8rem'}}>
                            <a onClick={() => onSelect(data)}>{data.nev}</a>
                        </h5>
                        <ul className="event-meta">
                            <li><i className="icon-time-line"></i>{data.adoszam}</li>
                            <li><i className="icon-map-pin-line"></i>{data.cim}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EventOne;
