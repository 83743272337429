import React from 'react';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import ContactUsForm from '../../components/contact/ContactUsForm';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/animations/phone-center.json'

const PhoneService = () => {
    return (
        <>
            <SEO title="Telefonközpont" />
            <Layout>
                <BreadcrumbOne
                    title="Telefonszámok, Virtuális alközpont"
                    rootUrl="/"
                    parentUrl="Termékeink"
                    currentUrl="Telefonközpont"
                />
                <div className="eduvibe-contact-us edu-contact-us-area edu-section-gap bg-color-white">
                    <div className="container eduvibe-animated-shape">
                        <div className="row g-5 mt--10">
                            <div className="col-lg-6">
                                <Lottie options={{
                                    loop: false,
                                    autoplay: true,
                                    animationData: animationData,
                                    rendererSettings: {
                                        preserveAspectRatio: 'xMidYMid slice'
                                    }
                                }} width={"485px"} height={"585px"}/>
                            </div>
                            <div className="col-lg-6">
                                <p className="text-part__wrap entry__one-step__wrap">
                                    <h3 className="title">Többfunkciós távközlési
                                        platforma a napi üzleti feladatok megoldásához</h3>
                                    <p className="text-part__text entry__one-step__text">Komplex szoftver termékeket
                                        fejlesztünk ki, hogy megkönnyítsük az ügyfelekkel, partnerekkel, szállítókkal
                                        való kommunikációt. Szerezzen szoftvermegoldásokat egy helyről!
                                    </p>
                                </p>
                            </div>
                        </div>

                        <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                            <div className="shape-image shape-image-1">
                                <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-2">
                                <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb" />
                            </div>
                            <div className="shape-image shape-image-3">
                                <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="edu-contact-map-area edu-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export default PhoneService;
