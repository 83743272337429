import React from "react";
import SEO from "../common/SEO";
import HappyGastroOld from "../pages/detailspages/HappyGastro";
import HappyPOS from "../pages/detailspages/HappyPOS";
import DeliveryModule from "../components/about/AboutFour";
import FooterOne from "../common/footer/FooterOne";
import CourierModule from "../components/about/CourierModule";
import TableReservationModule from "../components/about/TableReservationModule";
import VipModule from "../components/about/VipModule";
import Waitress from "../components/about/Waitress";
import DigitalMenu from "../components/about/DigitalMenu";
import Kiosk from "../components/about/Kiosk";
import CustomerNumberDisplay from "../components/about/CustomerNumberDisplay";
import OperatorModule from "../components/about/OperatorModule";
import DispatcherModule from "../components/about/DispatcherModule";
import WarehouseModule from "../components/about/WarehouseModule";
import ProductionModule from "../components/about/ProductionModule";
import BillingModule from "../components/about/BillingModule";
import CashRegisterModule from "../components/about/CashRegisterModule";
import ManagerModule from "../components/about/ManagerModule";
import ProcurementModule from "../components/about/ProcurementModule";
import DevelopersModule from "../components/about/DevelopersModule";
import KDSModule from "../components/about/KDSModule";
import FinanceModule from "../components/about/FinanceModule";
import HappyRestaurantModule from "../components/about/HappyRestaurantModule";
import TableMapModule from "../components/about/TableMapModule";
import Rights from "../components/about/Rights";

const HappySoftwares = () => {
  const isHomePage = window.location.pathname === "/";
  return (
    <>
      {!isHomePage && <SEO title="Szoftverek" />}
      {/* 1. HG éttermi modul */}
      <HappyRestaurantModule fromHome={true} />
      {false && <HappyGastroOld fromHome={true} />}
      {/* 2. HG házhozszállítás modul */}
      <DeliveryModule fromHome={true} />
      {/* 3. HG Asztaltérkép modul */}
      <TableMapModule fromHome={true} />
      {/* 4. Happy Mobil pincér */}
      <Waitress fromHome={true} />
      {/* 5. HG futár modul */}
      <CourierModule fromHome={true} />
      {/* 6. Jogosultságok kezelése */}
      <Rights fromHome={true} />
      {/* 7. HG asztalfoglalás modul */}
      <TableReservationModule fromHome={true} />
      {/* 8. HG Pénztárgép integráció */}
      <CashRegisterModule fromHome={true} />
      {/* 9. Számlázás modul */}
      <BillingModule fromHome={true} />
      {/* 10. Manager modul */}
      <ManagerModule fromHome={true} />
      {/* 11. Pénzügy modul */}
      <FinanceModule fromHome={true} />
      {/* 12. HG készlet modul */}
      <WarehouseModule fromHome={true} />
      {/* 13. HG gyártás modul */}
      <ProductionModule fromHome={true} />
      {/* 14. HG Beszerzés modul */}
      <ProcurementModule fromHome={true} />
      {/* 15. HG VIP modul */}
      <VipModule fromHome={true} />
      {/* 16. HG digitális menü */}
      <DigitalMenu fromHome={true} />
      {/* 17. HG Kiosk */}
      <Kiosk fromHome={true} />
      {/* 18. KDS modul */}
      <KDSModule fromHome={true} />
      {/* 19. HG Vevő sorszám modul */}
      <CustomerNumberDisplay fromHome={true} />
      {/* 20. H Operátor modul */}
      <OperatorModule fromHome={true} />
      {/* 21. HG Diszpécser modul */}
      <DispatcherModule fromHome={true} />
      {/* 22. Happy API */}
      <DevelopersModule fromHome={true} />

      {false && <HappyGastroOld fromHome={true} />}
      {false && <HappyPOS fromHome={true} />}
      {!isHomePage && <FooterOne />}
    </>
  );
};

export default HappySoftwares;
