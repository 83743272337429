import PropTypes from "prop-types";
import React from "react";
import {Helmet} from "react-helmet";

const SEO = ({
                 title,
                 description = `Vendéglátós szoftver, Éttermi rendszer, NTAK és sok más integráció, Mobile és PC POS, Fizetési szolgáltatások`
             }) => {
    return <Helmet>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta charSet="utf-8"/>
        {title && <title>{title} - Happy Gastro - {description}</title>}
        {description && <description>{description}</description>}
        <meta name="robots" content="index, follow"/>
    </Helmet>
  return (
      <>
        <meta charSet="utf-8"/>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
            <title>
                {title} - Happy Gastro - Vendéglátós szoftver, NTAK integráció, POS
                system
            </title>
            <meta name="robots" content="index, follow"/>
            <meta
                name="description"
                content={description}
            />
        </>
    );
};
SEO.propTypes = {
    title: PropTypes.string,
};

export default SEO;
