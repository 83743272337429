import React from "react";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import ContactUsForm from "../../components/contact/ContactUsForm";
import Lottie from "react-lottie";
import * as animationData from "../../assets/animations/customer-support.json";
import FooterOne from "../../common/footer/FooterOne";

const ContactUs = (props) => {
    const isHomePage = window.location.pathname === "/";
    const hideMenu = window.location.pathname !== '/szolgaltatasok/ugyfelszolgalat';
    const Container = (componentProps) => {
        return hideMenu
            ? <>
                {componentProps.children}
            </>
            : <Layout>
                {componentProps.children}
            </Layout>
    }

    const ContactInnerForm = () => {
        return <>
            <div
            className={`contact eduvibe-contact-us edu-contact-us-area ${!props.modalMode ? 'edu-section-gap' : ''} bg-color-white `}
            style={{minHeight: !props.modalMode ? 750 : 'fit-content'}}
        >
            <div className="container eduvibe-animated-shape"
                 style={{padding: props.modalMode ? '1.5rem 0' : ''}}>
                <div className="row g-5">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <span className="pre-title">Kérdése van, netán elakadt?</span>
                            <h3 className="title">Írjon nekünk üzenetet az alábbi űrlap kitöltésével!</h3>
                        </div>
                    </div>
                </div>
                <div className="row g-5 mt--20">
                    <div className="col-lg-6">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: animationData,
                                rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                },
                            }}
                            width={"fit-content"}
                            height={"auto"}
                        />
                    </div>
                    <div className="col-lg-6">
                        <ContactUsForm formStyle="rnt-contact-form rwt-dynamic-form row" modalMode={props.modalMode}/>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-04-01.png" alt="Shape Thumb"/>
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-02-08.png" alt="Shape Thumb"/>
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-15.png" alt="Shape Thumb"/>
                    </div>
                </div>
            </div>
        </div>
            </>
    }

    if(props.modalMode) return <ContactInnerForm modalMode={props.modalMode}/>
    return (
        <>
        {!props.fromHome && <SEO title="Elérhetőségeink és kapcsolat" description={"Kérdése van, netán elakadt? Itt vagyunk elérhetőek:"}/>}
            <Layout>
                <BreadcrumbOne
                    title="Elérhetőségeink és kapcsolat"
                    rootUrl="/kapcsolat/ugyfelszolgalat"
                    parentUrl="Ügyfélszolgálat"
                    currentUrl="Kapcsolat velünk"
                />
                        <Container>
                            <ContactInnerForm/>

                            {!props.modalMode && <div className="edu-contact-map-area edu-section-gapBottom">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12"></div>
                                    </div>
                                </div>
                            </div>}
                        </Container>
            </Layout>
            {!isHomePage && <FooterOne/>}
        </>
    );
};
export default ContactUs;
