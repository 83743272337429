import React from 'react';
import { Link } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
import SectionTitle from '../sectionTitle/SectionTitle';
import HourglassTopIcon from "@mui/icons-material/HourglassTop";

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';


const HomeFourAbout = () => {
    return (
        <div className="eduvibe-home-four-about edu-about-area about-style-2 edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape" style={{marginTop: -30, marginBottom: 30}}>
                <div className="row row--50">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <div className="eduvibe-about-1-img-wrapper">
                                <img className="image-1" src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/food-seach-screen.jpg" alt="Felhasználóbarát termék keresõ" />
                            </div>
                            <div className="circle-image-wrapper">
                                <img className="image-2" src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/module-changer.jpg" alt="Egyszerű használat" />
                                <div className="circle-image">
                                    <span></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="inner mt_md--40 mt_sm--40">
                            <SectionTitle
                                classes = "text-start"
                                slogan = "Mitöl jobb?"
                                title = "Íme legfontosabb funkcióink"
                            />
                            <ScrollAnimation
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}
                            >
                                <h6 className="subtitle mt--20">Legfontosabb moduljaink</h6>
                                <div className="about-feature-list">

                                    <div className="our-feature sal-animate" data-sal-delay="150"
                                         data-sal="slide-up" data-sal-duration="800">
                                        <div className="icon" style={{backgroundColor: '#000', color: 'white'}}>
                                            <ReceiptLongIcon color={'inherit'} sfontSize={'large'}/>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">Gyors nyugta</h6>
                                            <p className="feature-description" >
                                                gyorséttermek, bárok, pultos kiszolgálás
                                            </p>
                                        </div>
                                    </div>

                                    <div className="our-feature sal-animate" data-sal-delay="150"
                                         data-sal="slide-up" data-sal-duration="800">
                                        <div className="icon" style={{backgroundColor: '#7EC0FCFF', color: 'white'}}>
                                            <i className="fa-solid fa-chair"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">
                                                Asztaltérkép
                                            </h6>
                                            <p className="feature-description">
                                                szabadon szerkeszthetō asztaltérkép, korláltlan* szintek és területek, átültetés asztalok között
                                            </p>
                                        </div>
                                    </div>

                                    <div className="our-feature sal-animate" data-sal-delay="150"
                                         data-sal="slide-up" data-sal-duration="800">
                                        <div className="icon" style={{backgroundColor: '#F7CC45', color: 'white'}}>
                                            <i className="fa-solid fa-person-biking"></i>
                                        </div>
                                        <div className="feature-content">
                                            <h6 className="feature-title">
                                                Házhozszállítás
                                            </h6>
                                            <p className="feature-description">
                                                Wolt, Foodora és egyéb integráció, saját webshop megoldások, manuális cím felvitel, futárokra osztás és útvonaltervezés
                                            </p>
                                        </div>
                                    </div>


                                </div>


                            </ScrollAnimation>
                        </div>
                    </div>
                </div>

                <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape-image shape-image-1">
                        <img src="/images/shapes/shape-11-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-2">
                        <img src="/images/shapes/shape-08-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape-image shape-image-3">
                        <img src="/images/shapes/shape-30.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-1">
                        <span className="shape-dot"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeFourAbout;
