import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import Layout from "../../common/Layout";

const TableReservationModule = (props) => {
    const isHomePage = window.location.pathname === "/" || window.location.pathname === "/termekeink/szoftver";
    return (
        <Layout>
            {!props.fromHome && <SEO title={"Asztalfoglalás modul"}
                                     description={"Egy felületen kezelhető és automatizálható a különböző platformról beérkező asztalfoglalások. Egyedi igények egyránt feljegyezhetők, hogy az érkezés előtt már minden a Vendég kérései szerint legyen elrendezve, legyen az telefonos, személyes vagy az applikációban/weboldalon jelzett asztalfoglalási szándék."}/>}
            <BreadcrumbOne
                style={{backgroundImage: `url('https://images.unsplash.com/photo-1550966871-3ed3cdb5ed0c?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`}}
                title={"<font style='color: white'>Asztalfoglalás modul</font>"}
                rootUrl="/"
                parentUrl={"<font style='color: white'>Termékeink</font>"}
                currentUrl={"<font style='color: white'>Asztalfoglalás modul</font>"}
            />
            <div className="edu-about-area about-style-3 edu-section-gap bg-image">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5">

                        <div className="col-lg-6" id={"delivery-module"}>
                            <div className="inner">
                                <SectionTitle
                                    classes="text-start"
                                    slogan="Minden asztalfoglalás egy helyen!"
                                    title="Asztalfoglalás modul"
                                />
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                >
                                    <p className="description mt--40">
                                        Egy felületen kezelhető és automatizálható a különböző platformról beérkező
                                        asztalfoglalások. Egyedi igények egyránt feljegyezhetők, hogy az érkezés előtt
                                        már minden a Vendég kérései szerint legyen elrendezve, legyen az telefonos,
                                        személyes vagy az applikációban/weboldalon jelzett asztalfoglalási szándék.
                                    </p>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeIn"
                                    animateOut="fadeInOut"
                                    className="testimonial-left-image"
                                    animateOnce={true}
                                    style={{margin: '5rem 0'}}
                                >
                                    <div className="thumbnail">
                                        <div className="circle-image-wrapper" style={{width: "37rem"}}>
                                            <img
                                                style={{width: '35rem', height: "35rem", objectFit: 'cover'}}
                                                className="radius-round"
                                                src="https://images.unsplash.com/photo-1635548166842-bf67bacbefaa?q=80&w=3387&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                alt="Futár modul"/>
                                            <div className="circle-image">
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>

                        <div className="col-lg-6" id={"delivery-module"}>
                            <div className="inner">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                Központi asztalfoglalás
                                            </h6>
                                            <p className="description">
                                                Egyszerű, hatékony, áttekinthető.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                Automatizált foglalás kezelés
                                            </h6>
                                            <p className="description">
                                                Nincs több dupla foglalás, nincs több stressz.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                Egyedi igények kezelése
                                            </h6>
                                            <p className="description">
                                                Minden vendég kívánsága teljesül.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                A Happy Gastro Asztalfoglalás modul
                                            </h6>
                                            <p className="description">
                                                A vendégelégedettség garanciája!
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb"/>
                        </div>
                    </div>
                </div>
            </div>
            {!isHomePage && <FooterOne/>}
        </Layout>
    );
};

export default TableReservationModule;
