import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import Layout from "../../common/Layout";

const VipModule = (props) => {
    const isHomePage = window.location.pathname === "/" || window.location.pathname === "/termekeink/szoftver";
    return (
        <Layout>
            {!props.fromHome && <SEO title={"Házhozszállítási modulunk"}
                                     description={"Lojalitásból csillagos ötös! A törzsvendégekrő tudnil,a  törzsvendégekért megtenni mindent! A legfontosabb a Vendég és az egyenlők között is első a Törzsvendég, akikről már kérdezés nélkül is tudható, mit szeret az étlapon, melyik a kedvenc asztala, milyen ételekre allergiás, de ezen kívül még számos információ összegyűjthető, hogy igazán otthonosan érezze magát, mint egy baráti társaságban."}/>}
            <BreadcrumbOne
                style={{backgroundImage: `url('https://images.unsplash.com/photo-1620843437920-ead942b3abd3?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`}}
                title={"<font style='color: white'>Törzsvendég modul</font>"}
                rootUrl="/"
                parentUrl={"<font style='color: white'>Termékeink</font>"}
                currentUrl={"<font style='color: white'>Törzsvendég modul</font>"}
            />
            <div className="edu-about-area about-style-3 edu-section-gap bg-image">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5" style={{flexDirection: "row-reverse"}}>

                        <div className="col-lg-6" id={"delivery-module"}>
                            <div className="inner">
                                <SectionTitle
                                    classes="text-start"
                                    slogan="Kényeztesd a törzsvendégeidet!"
                                    title="Törzsvendég modul"
                                />
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                >
                                    <p className="description mt--40">
                                        Lojalitásból csillagos ötös! A törzsvendégekrő tudnil,a törzsvendégekért
                                        megtenni mindent! A legfontosabb a Vendég és az egyenlők között is első a
                                        Törzsvendég, akikről már kérdezés nélkül is tudható, mit szeret az étlapon,
                                        melyik a kedvenc asztala, milyen ételekre allergiás, de ezen kívül még számos
                                        információ összegyűjthető, hogy igazán otthonosan érezze magát, mint egy baráti
                                        társaságban.
                                    </p>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeIn"
                                    animateOut="fadeInOut"
                                    className="testimonial-left-image"
                                    animateOnce={true}
                                    style={{margin: '5rem 0'}}
                                >
                                    <div className="thumbnail">
                                        <div className="circle-image-wrapper" style={{width: "37rem"}}>
                                            <img
                                                style={{width: '35rem', height: "35rem", objectFit: 'cover'}}
                                                className="radius-round"
                                                src="https://images.unsplash.com/photo-1622461962082-fc68f58622ed?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8dmlwJTIwZ3Vlc3R8ZW58MHx8MHx8fDA%3D"
                                                alt="Futár modul"/>
                                            <div className="circle-image">
                                                <span></span>
                                                <span></span>
                                            </div>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>

                        <div className="col-lg-6" id={"delivery-module"}>
                            <div className="inner">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                Ismerd meg a vendégeidet
                                            </h6>
                                            <p className="description">
                                                Tudd, mit szeretnek és mire van szükségük.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                Egyéni bánásmód
                                            </h6>
                                            <p className="description">
                                                Érezzék magukat otthon a vendéglátóhelyed falai között.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                Hűségprogram
                                            </h6>
                                            <p className="description">
                                                Jutalmazd a hűségüket.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>

                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title d-flex align-items-center">
                                                <i className="ri-arrow-drop-right-line"></i>
                                                A Happy Gastro Törzsvendég modul
                                            </h6>
                                            <p className="description">
                                                A vendégelégedettség kulcsa!
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb"/>
                        </div>
                    </div>
                </div>
            </div>
            {!isHomePage && <FooterOne/>}
        </Layout>
    );
};

export default VipModule;
