import React, { useState } from "react";

import InputMask from "react-input-mask";
import axios from "axios";
import SearchCompany from "./SearchCompany";
import serialize from "form-serialize";
import SectionTitle from "../sectionTitle/SectionTitle";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Checkbox } from "@mui/material";
import sha256 from "sha256";

const Result = ({ result, setResult }) => {
  return (
    <div>
      <div className="alert text-center" role="alert">
        <h4
          className="alert-heading text-center"
          style={{ fontSize: "2.4rem" }}
        >
          Köszönjük üzenetét, hamarosan keresni fogjuk!
        </h4>
      </div>

      <br />
      <br />

      <div className="col-lg-12" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <button
          className="rn-btn edu-btn w-100"
          type="submit"
          onClick={() => setResult(false)}
        >
          <i className="icon-arrow-left-line-right"></i>
          <span>Új üzenet írása</span>
        </button>
      </div>
    </div>
  );
};

function SignUpForm(props) {
  const { formStyle } = props;
  const [result, setResult] = useState(false);
  const [processingSignUp, setProcessingSignUp] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [acceptAszf, setAcceptAszf] = React.useState(false);
  const [acceptNewsletter, setAcceptNewsletter] = React.useState(true);

  const processSignUp = (e) => {
    e.preventDefault();
    if (!acceptAszf) {
      setProcessingSignUp(false);
      return Swal.fire({
        icon: "error",
        title: "Valami nem sikerült,",
        text: "Kérjük fogadja el Általános Szerződési feltételeinket!",
        confirmButtonColor: "#f1b44c",
      });
    }
    if (!selectedCompany || !selectedCompany.nev) {
      setProcessingSignUp(false);
      return alert("Kérjük válassza ki vállalkozását a cégkeresõbõl!");
    }
    let formData = serialize(document.querySelector("#signup-form"), {
      hash: true,
      empty: true,
    });
    axios({
      method: "post",
      url: `https://api.happygastro.org/api/restaurant/signup/initial`,
      headers: { "Content-Type": "application/json" },
      data: {
        ...formData,
        companyData: selectedCompany,
      },
    })
      .then((res) => {
        window.gtag("event", "contact_form", {
          method: "Happy Gastro Web contact form",
          ...formData,
          companyData: selectedCompany,
        });
        setProcessingSignUp(false);
        localStorage.setItem("signupRespoonse", JSON.stringify(res.data));
        props.setRegisteredData(res.data);
        props.setCurrentStep(2);
      })
      .catch((error) => {
        setProcessingSignUp(false);
        if (false && error.response.data.code === "used_vatNo") {
          return alert(
            "Ezt az adószámot már egy másik partnerünk használja. Kérjük, hogy mindenképp vegye fel velünk a kapcsolatot, ha nem Ōn regisztrált!"
          );
        }
        if (false && error.response.data.code === "only_one_restaurant") {
          return alert(
            "Jelenleg egy fiókkhoz csak egyetlen étterem (hálózat) csatolható! Kérjük vegye fel velünk a kapcsolatot, kollégáink készséggel állnak rendelkezésére."
          );
        }
        console.log(error);
        alert(
          "Sajnáljuk, de nem várt technikai hiba lépett fel. Kérjük prôbálja újr késöbb, vagy keressen minket ügyfélszolgálati vonalainkon egyiké!"
        );
      });
  };

  return (
    <div>
      {result ? (
        <Result setResult={setResult} result={result} />
      ) : (
        <form
          aria-disabled={processingSignUp}
          className={`${formStyle}`}
          onSubmit={(e) => {
            e.preventDefault();
            if (processingSignUp) return false;
            setProcessingSignUp(true);
            processSignUp(e);
          }}
          id={"signup-form"}
        >
          <div className="col-lg-12">
            <div className="form-group">
              <input
                autoFocus
                autoCapitalize
                disabled={processingSignUp}
                type="text"
                className="form-control form-control-lg"
                name="restaurantName"
                placeholder="Üzlete megnevezése*"
                required
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <SearchCompany
                disabled={processingSignUp}
                selectedCompany={selectedCompany}
                onSelect={(c) => {
                  setSelectedCompany(c);
                  document.getElementById("search-input").value = "";
                  document.getElementById("search-input").focus();
                  //props.setRegisteredData(c)
                }}
              />
            </div>
            {selectedCompany && (
              <div
                className={`edu-event event-list radius-small bg-white`}
                onClick={() => {
                  setSelectedCompany(null);
                }}
                style={{ marginTop: -20, cursor: "pointer", marginBottom: 15 }}
              >
                <div className="inner">
                  <div className="content">
                    <div className="content-left">
                      <h5 className="title" style={{ fontSize: "1.8rem" }}>
                        <a>{selectedCompany.nev}</a>
                      </h5>
                      <ul className="event-meta">
                        <li>
                          <i className="icon-time-line"></i>
                          {selectedCompany.adoszam}
                        </li>
                        <li>
                          <i className="icon-map-pin-line"></i>
                          {selectedCompany.cim}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <h5 style={{ marginLeft: 50 }}>Az Ön adatai:</h5>

          <div className="col-lg-12">
            <div className="form-group">
              <input
                autoCapitalize
                disabled={processingSignUp}
                type="text"
                className="form-control form-control-lg"
                name="fullname"
                placeholder="Teljes neve*"
                required
              />
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <input
                autoCapitalize={false}
                disabled={processingSignUp}
                type="email"
                className="form-control form-control-lg"
                name="email"
                placeholder="Email*"
                required
              />
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <InputMask
                disabled={processingSignUp}
                className="form-control form-control-lg"
                autoComplete="off"
                name="phone"
                id="phone"
                mask="+36 (99) 999-9999"
                placeholder="Telefonszám*"
                required
              />
            </div>
          </div>
          <div className="col-lg-12">
            <div
              className="aszf"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Checkbox
                checked={acceptAszf}
                onChange={(e) =>
                  setAcceptAszf((prevState) => {
                    return !prevState;
                  })
                }
              />
              <a
                href={`${window.location.origin}/aszf`}
                target="_blank"
                rel="noreferer noreferrer"
              >
                <p className={"m-0"}>
                  Átlalános Szerződési Feltételek elfogadása<sup>*</sup>
                </p>
              </a>
            </div>
          </div>
          <div className="col-lg-12">
            <div
              className="aszf"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Checkbox
                checked={acceptNewsletter}
                onChange={(e) =>
                  setAcceptNewsletter((prevState) => {
                    return !prevState;
                  })
                }
              />
              <p className={"m-0"}>Feliratkozom a Happy Gastro hírlevelére.</p>
            </div>
          </div>

          <div className="col-lg-12">
            {!processingSignUp && (
              <button className="rn-btn edu-btn w-100" type="submit">
                <span>Regisztráció folytatása</span>
                <i className="icon-arrow-right-line-right"></i>
              </button>
            )}
            {processingSignUp && (
              <button className="rn-btn edu-btn w-100" type="submit">
                <span>Adatok feldolgozása...</span>
                <i className="bx bx-spin eduvibe-spin-icon"></i>
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );
}

export default SignUpForm;
