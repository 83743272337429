import React, { useState, useEffect } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import {FaArrowLeft, FaExpandArrowsAlt, FaMinus, FaPlus, FaUndo} from 'react-icons/fa';

const ZoomableImage = (props) => {
    const imageUrl = 'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/Happy-Gastro-System-Diagram-2023.webp';
    const [initialScale, setInitialScale] = useState(setScale());
    const [initialPosition, setInitialPosition] = useState(setPosition());

        function setScale() {
            if(/Mobi|Tablet|iPad|iPhone|Android/i.test(navigator.userAgent) || window.innerWidth <= 768){
                return 3.3
            }
            return 1
        }
    function setPosition() {
            let posi;
        const img = new Image();
        img.src = imageUrl;
            const centerX = (img.width * 3 - window.innerWidth) / 2;
            const centerY = (img.height * 3 - window.innerHeight) / 2;
            posi= { x: -383, y: -300 };
        return posi;
    }
        useEffect(() => {
            setPosition();
            }, []);


    const handleImageClick = () => {
        if (!props.isBannerFullScreen) {
            props.setIsBannerFullScreen(true);
        }
    };

    return (
        <>
            {props.isBannerFullScreen ? (
                <div style={{
                    display: 'flex',
                    height: '100vh',
                    margin: '0 auto',
                    backgroundColor: 'rgba(255,217,171,0.78)',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    zIndex: 9999,
                    alignItems: 'center',
                    justifyContent: 'center',
                    justifyItems: 'center',
                }}>
                    <FaArrowLeft
                        style={{
                            position: 'absolute',
                            top: '20px',
                            left: '20px',
                            cursor: 'pointer',
                            color: 'black',
                            fontSize: '24px',
                            zIndex: 1000
                        }}
                        onClick={() => props.setIsBannerFullScreen(false)}
                    />
                    <TransformWrapper
                        initialScale={initialScale}
                        initialPositionX={initialPosition.x}
                        initialPositionY={initialPosition.y}
                        minScale={0.8}
                        maxScale={6}
                        wheel={{ step: 0.1 }}
                        pinch={{ disabled: false, step: 0.1 }}
                        doubleClick={{ disabled: false }}
                    >
                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                            <React.Fragment>
                                <div className="tools">
                                    <div className="tools">
                                        <div className="tools">
                                            <FaUndo
                                                style={{
                                                    position: 'absolute',
                                                    top: '20px',
                                                    right: '140px',
                                                    cursor: 'pointer',
                                                    color: 'black',
                                                    fontSize: '28px',
                                                    zIndex: 1000,
                                                    borderRadius: '50%',
                                                    backgroundColor: 'white',
                                                    padding: '5px'
                                                }}
                                                onClick={() => resetTransform()}
                                            />
                                            <FaPlus
                                                style={{
                                                    position: 'absolute',
                                                    top: '20px',
                                                    right: '80px',
                                                    cursor: 'pointer',
                                                    color: 'black',
                                                    fontSize: '28px',
                                                    zIndex: 1000,
                                                    borderRadius: '50%',
                                                    backgroundColor: 'white',
                                                    padding: '5px'
                                                }}
                                                onClick={() => zoomIn()}
                                            />
                                            <FaMinus
                                                style={{
                                                    position: 'absolute',
                                                    top: '20px',
                                                    right: '0',
                                                    cursor: 'pointer',
                                                    color: 'black',
                                                    fontSize: '28px',
                                                    zIndex: 1000,
                                                    borderRadius: '50%',
                                                    backgroundColor: 'white',
                                                    padding: '5px',
                                                    marginRight: '20px'
                                                }}
                                                onClick={() => zoomOut()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <TransformComponent>
                                    <img
                                        style={{
                                            margin: 'auto',
                                            cursor: 'pointer',
                                            zIndex: 900,
                                            width: '100%',
                                            height: 'auto'
                                        }}
                                        src={imageUrl}
                                        alt="Happy Gastro Cloud system diagram"
                                    />
                                </TransformComponent>
                            </React.Fragment>
                        )}
                    </TransformWrapper>
                </div>
            ) : ( (window.innerWidth) <= 768 ? <>
                        <div onMouseEnter={(e) => e.currentTarget.firstChild.style.opacity = 1}
                             onMouseLeave={(e) => e.currentTarget.firstChild.style.opacity = 0.37}
                             style={{display: 'flex', width: '80%', margin: '0 auto', position: 'relative'}}
                             onClick={handleImageClick}>
                            <FaExpandArrowsAlt
                                style={{
                                    position: 'absolute',
                                    top: '10px',
                                    right: '10px',
                                    cursor: 'pointer',
                                    color: 'rgba(0,0,0)',
                                    opacity: 0.37,
                                    fontSize: '24px',
                                    zIndex: 1000,
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
                                onMouseLeave={(e) => e.currentTarget.style.opacity = 0.37}
                            />
                            <img
                                style={{margin: '0 auto', cursor: 'pointer'}}
                                src={imageUrl}
                                alt="Happy Gastro Cloud system diagram"
                            />
                        </div>
                    </> :
                    <div style={{display: 'flex', width: '80%', margin: '0 auto', position: 'relative'}}
                         onClick={handleImageClick}>
                        <img
                            style={{margin: '0 auto', cursor: 'pointer'}}
                            src={imageUrl}
                            alt="Happy Gastro Cloud system diagram"
                        />
                    </div>
            )}
        </>
    );
};

export default ZoomableImage;