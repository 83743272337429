const happy_reducer = (state, action) => {
    switch (action.type) {
        case 'SET_IS_LOGGED_IN': {
            localStorage.setItem('isLoggedIn', action.value)
            return {
                ...state,
                isLoggedIn: action.value,
            };
        }

        default:
            return state;
    }
};

export default happy_reducer;
