import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import Layout from "../../common/Layout";

const DeliveryModule = (props) => {
    const isHomePage = window.location.pathname === "/" || window.location.pathname === "/termekeink/szoftver";
    return (
    <Layout>
        {!props.fromHome && <SEO title={"Házhozszállítási modulunk"} description={"Inteligens vizuális és autómata útvonal és futár kezelés, elszámolás"}/>}
        <BreadcrumbOne
            style={{backgroundImage: `url('https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/delivery-module-banner.jpg')`}}
            title={"<font style='color: white'>Házhozszállítás modul</font>"}
            rootUrl="/"
            parentUrl={"<font style='color: white'>Termékeink</font>"}
            currentUrl={"<font style='color: white'>Házhozszállítás modul</font>"}
        />
            <div className="edu-about-area about-style-3 edu-section-gap bg-image">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6 pr--80">
                            <ScrollAnimation
                                animateIn="fadeIn"
                                animateOut="fadeInOut"
                                className="gappery-wrapper"
                                delay={150}
                                animateOnce={true}
                            >
                                <div className="row g-5 align-items-end">
                                    <div className="col-lg-5 col-md-6">
                                        <div className="gallery-image mt--85">
                                            <img
                                                className="w-100"
                                                src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/delivery-module.jpg"
                                                alt="Happy Gastro Házhozszállítás"
                                            />
                                            <div className="icon-badge">
                                                <i className="icon-ribbon"></i>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-7 col-md-6">
                                        <div className="gallery-image">
                                            <img
                                                className="w-100"
                                                src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/delivery-statistics.jpg"
                                                alt="Gappy Gastro Cloud admin vezérlöpult"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="gallery-image gallery-image-3 text-center">
                                            <img
                                                src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/foooldal-szamla-adatlap.jpg"
                                                alt="Happy Gastro részletes számla elemzés"
                                            />
                                            <div className="student-like-status bounce-slide">
                                                <div className="inner">
                                                    <div className="icon">
                                                        <i className="icon-map-pin-line"></i>
                                                    </div>
                                                    <div className="content">
                                                        <h6 className="title">Új beérkező rendelés</h6>
                                                        <span className="subtitle">
                              Kovács Olivér - Házhozszállítás
                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ScrollAnimation>
                        </div>

                        <div className="col-lg-6" id={"delivery-module"}>
                            <div className="inner">
                                <SectionTitle
                                    classes="text-start"
                                    slogan="Ételt a helyszínre, egyszerűen, hatékonyan!"
                                    title="Házhozszállítás modul"
                                />
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                >
                                    <p className="description mt--40">
                                        A Happy Gastro számos más rendszerrel integrálható, mint a Foodora, Wolt, Számlázz.hu, Billingo, hogy megkönnyítse az online kapcsolódást. Rendelésfelvétel telefonon házhoz és elvitelre. Vendég keresése telefonszám és/vagy név alapján, így nem kell újra begépelni a címet, ha már a rendszerben van.
                                        Az integrált oldalakról (Wolt, foodora) beérkező online rendelések kezelése és továbbítása a konyha felé. Az elkészült rendelések futárhoz rendelése… és már mehet is!.
                                    </p>
                                </ScrollAnimation>
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    className="col-lg-12 p-0"
                                    animateOnce={true}
                                >
                                    <div className="edu-feature-list">
                                        <div className="content">
                                            <h6 className="title">Integrálható</h6>
                                            <p className="description">
                                                Foodora, Wolt, Számlázz.hu, Billingo - minden egy helyen.
                                            </p>
                                        </div>
                                    </div>
                                </ScrollAnimation>

                                <div className="feature-style-5 row g-5">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        className="col-lg-12 col-xl-6"
                                        animateOnce={true}
                                    >
                                        <div className="edu-feature-list">
                                            <div className="icon">
                                                <i className="icon-Board"></i>
                                            </div>
                                            <div className="content">
                                                <h6 className="title">Egyszerű</h6>
                                                <p className="description">
                                                    Könnyű online kapcsolódás.
                                                </p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        className="col-lg-12 col-xl-6"
                                        animateOnce={true}
                                    >
                                        <div className="edu-feature-list">
                                            <div className="icon">
                                                <i className="icon-Bag"></i>
                                            </div>
                                            <div className="content">
                                                <h6 className="title">Hatékony</h6>
                                                <p className="description">
                                                    Simább munkafolyamat, boldogabb vendégek.
                                                </p>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb"/>
                        </div>
                    </div>
                </div>
            </div>
            {!isHomePage && <FooterOne/>}
    </Layout>
    );
};

export default DeliveryModule;
