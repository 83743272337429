import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Layout from "../common/Layout";
import FooterOne from "../common/footer/FooterOne";
import SectionTitle from "../components/sectionTitle/SectionTitle";
import SEO from "../common/SEO";

const items = [
  {
    title: "Foodora",
    info: "Ételrendelési platform",
    icon: "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/Foodora_Logo_Cherry%20Pink_RGB.png",
    link: "https://www.foodora.hu/?origin=www.happygastro.hu",
    iconHeight: 60,
  },
  {
    title: "Wolt",
    info: "Ételrendelési platform",
    icon: "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/wolt.png",
    link: "https://wolt.com/hu/hun?origin=www.happygastro.hu",
    iconHeight: 26,
  },
  {
    title: "Falatozz",
    info: "Ételrendelési platform",
    icon: "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/falatozzhu_logo_szines_v2.svg",
    link: "https://falatozz.hu/?origin=www.happygastro.hu",
    iconHeight: 26,
  },
  {
    title: "Számlázz",
    info: "Ételrendelési platform",
    icon: "https://www.szamlazz.hu/wp-content/uploads/2023/09/szamlazzhu_logo-horizontal-1_color.png",
    link: "https://www.szamlazz.hu/?origin=www.happygastro.hu",
    iconHeight: 50,
  },
  {
    title: "Billingo",
    info: "Online számlázó program",
    icon: "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/billingo.png",
    link: "https://www.billingo.hu/?origin=www.happygastro.hu",
    iconHeight: 30,
  },
  {
    title: "iBar",
    info: "Leltározás és standolás éttermeknek",
    icon: "https://static.wixstatic.com/media/36b443_d327d78c2c034adcb1cdbec57d24b8a7~mv2.png/v1/fill/w_188,h_98,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ibar_smart_white_edited.png",
    link: "https://www.ibar.ai/",
    filter: "invert(1)",
    iconHeight: 30,
  },
  {
    title: "Querko",
    info: "Gyors QR befizetések",
    icon: "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/qerko.webp",
    link: "https://www.qerko.com/hu?origin=www.happygastro.hu",
    iconHeight: 45,
  },
  {
    title: "Viva payments",
    info: "Elektronikus pénzintézet",
    icon: "https://mma.prnewswire.com/media/2162532/Viva_Wallet_Logo.jpg?p=twitter",
    link: "https://www.vivawallet.com/hu_hu?origin=www.happygastro.hu",
    iconHeight: 45,
  },
  {
    title: "Stripe",
    info: "Elektronikus pénzintézet",
    icon: "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/stripe.png",
    link: "https://stripe.com/en-hu?origin=www.happygastro.hu",
    iconHeight: 45,
  },
  {
    title: "Teya",
    info: "Elektronikus pénzintézet",
    icon: "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/teya.png",
    link: "https://www.teya.com/hu/?origin=www.happygastro.hu",
    iconHeight: 45,
  },
  {
    title: "Hellopay",
    info: "Innovatív fizetési megoldás",
    icon: "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/hellopay.png",
    link: "https://www.hellopay.hu/?origin=www.happygastro.hu",
    iconHeight: 40,
  },
  {
    title: "SimplePay",
    info: "Széleskörű fizetési megoldások",
    icon: "https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/integrations_images/simplepay.png",
    link: "https://simplepay.hu/?origin=www.happygastro.hu",
    iconHeight: 35,
  },
];

const Integrations = ({ wrapperClass, styleClass }) => {
  const isHomePage = window.location.pathname === "/";
  return (
    <>
      <Layout>
        <SEO title="Integrációk" />
        <div className="integrations-wrapper">
          <div
            className="sercice-area eduvibe-service-four  bg-color-white"
            style={{ padding: "4rem 0" }}
          >
            <div className="container eduvibe-animated-shape">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle classes="text-center" title="Integrációk" />
                </div>
              </div>
              <div className={`row ${wrapperClass || "g-5 mt--25"}`}>
                {items.map((data, i) => (
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={`col-lg-3 col-md-6 col-sm-6 col-12 ${
                      styleClass ? styleClass : ""
                    }`}
                    animateOnce={true}
                    key={i}
                  >
                    <a
                      href={data.link}
                      target={"_blank"}
                      className="service-card service-card-4"
                    >
                      <div className="inner d-flex flex-column justify-content-sm-between">
                        <div
                          className="icon"
                          style={{ maxHeight: data.iconHeight }}
                        >
                          <img
                            src={data.icon}
                            alt={data.title}
                            style={{ height: "100%", mixBlendMode: "multiply", filter: data.filter || "none"}}
                          />
                        </div>
                        <div className="content">
                          <h6 className="title">
                            <a href={data.link}>{data.title}</a>
                          </h6>
                          <p className="description">{data.info}</p>
                        </div>
                      </div>
                    </a>
                  </ScrollAnimation>
                ))}
              </div>
              <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                <div className="shape-image shape-image-1">
                  <img src="/images/shapes/shape-11-02.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-2">
                  <img src="/images/shapes/shape-02-03.png" alt="Shape Thumb" />
                </div>
                <div className="shape-image shape-image-3">
                  <img src="/images/shapes/shape-20.png" alt="Shape Thumb" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!isHomePage && <FooterOne />}
      </Layout>
    </>
  );
};

export default Integrations;
