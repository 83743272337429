import React, { Component } from "react";
import axios from "axios";
import EventOne from "../event/EventOne";

class SearchCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      results: {},
      loading: false,
      message: "",

      totalResults: 0,
      totalPages: 0,
      currentPageNo: 0,
    };

    this.cancel = "";
  }

  getPageCount = (total, denominator) => {
    const divisible = 0 === total % denominator;
    const valueToBeAdded = divisible ? 0 : 1;

    return Math.floor(total / denominator) + valueToBeAdded;
  };

  fetchSearchResults = (updatedPagNo = "", query) => {
    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios({
      method: "get",
      url: `${window.api}/restaurant/name/${query}/search`,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        const total = res.data.total;
        const totalPagesCount = this.getPageCount(total, 20);

        const resultNotFoundMsg = !res.data.suggestions.length
          ? "Sajnos nem találtunk egyetlen vállalkozást sem ezzel a névvel!"
          : "";
        this.setState({
          results: res.data.suggestions.slice(0, 3),
          message: resultNotFoundMsg,
          totalResults: total,
          totalPages: totalPagesCount,
          currentPageNo: updatedPagNo,
          loading: false,
        });
      })
      .catch((error) => {
        console.error(error);
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message:
              "Valami hiba történt a céginformációhoz valo kapcsolodás közben",
          });
        }
      });
  };

  handlePageClick = (type) => {
    // event.preventDefault();

    const updatePageNo =
      "prev" === type
        ? this.state.currentPageNo - 1
        : this.state.currentPageNo + 1;

    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
          message: "",
        },
        () => {
          this.fetchSearchResults(updatePageNo, this.state.query);
        }
      );
    }
  };

  renderSearchResults = () => {
    const { results } = this.state;

    if (Object.keys(results).length && results.length) {
      return (
        <div
          className="results-container"
          style={{
            zIndex: 9999,
            position: "absolute",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
          }}
        >
          {results.map((result) => {
            return (
              <EventOne
                data={result}
                onSelect={() => {
                  this.props.onSelect(result);
                  this.setState({
                    query: result.nev,
                    results: [],
                    totalResults: 0,
                    totalPages: 0,
                    currentPageNo: 0,
                  });
                }}
              />
            );
          })}
        </div>
      );
    }
  };

  handleChange = (event) => {
    const query = event.target.value;

    if (!query) {
      this.setState({
        query,
        results: {},
        message: "",
        totalResults: 0,
        totalPages: 0,
        currentPageNo: 0,
      });
    } else {
      this.setState(
        {
          query: query,
          loading: true,
          message: "",
        },
        () => {
          this.fetchSearchResults(1, query);
        }
      );
    }
  };

  render() {
    const { query, loading, message, currentPageNo, totalPages } = this.state;

    const showPrevLink = 1 < currentPageNo;
    const showNextLink = totalPages > currentPageNo;

    return (
      <div>
        <input
          style={this.props.style}
          type="text"
          value={query}
          autoComplete={"none"}
          name="query"
          id="search-input"
          placeholder="Vállalkozása, szervezet teljes neve"
          className="form-control form-control-lg"
          onChange={this.handleChange}
        />
        {/* Error message */}
        {message && (
          <p
            className="message text-center mt--5 mb--5"
            style={{ fontWeight: 700 }}
          >
            {message}
          </p>
        )}

        {/* Result */}
        {this.renderSearchResults()}
      </div>
    );
  }
}

export default SearchCompany;
