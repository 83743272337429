import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const items = [
    {
        title: 'Rendelkezésre állás',
        info: 'Bármikor szüksége lenne ránk, segítünk!',
        numberOfCourses: '24/7 Support',
        icon: 'icon-student-read',
        link: '#'
    },
    {
        title: 'Ingyen webáruház minden csomaghoz',
        info: 'Ingyen webáruházat készítünk egyedi igényeid szerint.',
        numberOfCourses: 'Teljesen testreszabva',
        icon: 'icon-lab',
        link: '#'
    },
    {
        title: 'Részletes forgalmi kimutatás',
        info: 'Mindenre kiterjedő forgalmi kimutatással rendelkezünk!',
        numberOfCourses: 'Testreszabható dashboard',
        icon: 'icon-compass',
        link: '#'
    },
    {
        title: 'Segítség a kezdetektől',
        info: 'Új vagy még, esetleg váltanál? Mindenben segítünk!',
        numberOfCourses: 'Telepítés és beállítás',
        icon: 'icon-calculator',
        link: '#'
    },
    {
        title: 'Folyamatosan bővülő funkciók',
        info: 'Mi nem állunk meg, minden nap dolgozunk, hogy jobbak legyünk!',
        numberOfCourses: 'Ügyfeleink visszajelzése alapján',
        icon: 'icon-microscopes',
        link: '#'
    },
    {
        title: 'Integrációk sokasága',
        info: 'Legyen szó pénztárgépről vagy számlázásról, bármire tudunk integrációt!',
        numberOfCourses: 'Egyedi igények alapján',
        icon: 'icon-calculator',
        link: '#'
    },
    {
        title: 'Könnyő kezelhetőség',
        info: 'Nincs szükség több órás esetleg napos betanításra, azonnal használatba vehető!',
        numberOfCourses: 'Súgónk bármikor segít benne',
        icon: 'icon-pen-tool',
        link: '#'
    },
    {
        title: 'Vendéglátósoktól vendéglátósoknak',
        info: 'Nem Neked - Mi fogunk alkalmazkodni hozzád!',
        numberOfCourses: '30+ év vendéglátás',
        icon: 'icon-science',
        link: '#'
    }
];

const CategoryTwo = ( { wrapperClass, styleClass } ) => {
    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` }>
            { items.map( ( data , i ) => (
                <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-3 col-md-6 col-sm-6 col-12 ${ styleClass ? styleClass : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className="service-card service-card-4">
                        <div className="inner">
                            <div className="icon">
                                <i className={ data.icon }></i>
                                <span className="subtitle">{ data.numberOfCourses }</span>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.info }</p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default CategoryTwo;