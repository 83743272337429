import React from "react";
import { priceFormatter } from "../../utils";
import Layout from "../../common/Layout";
import SEO from "../../common/SEO";

const Devices = ({ devices, setDevices, vatKey }) => {
  const filteredDevices = devices ? devices : [];
  const getGrossPrice = (price) => {
    return price * (vatKey || 1);
  };
  const Container = (componentProps) => {
    return devices ? (
      <>{componentProps.children}</>
    ) : (
      <Layout>{componentProps.children}</Layout>
    );
  };
  return (
    <Container>
      <SEO title="Eszközök" />
      <div
        className="container eduvibe-animated-shape"
        style={{ padding: "5rem 0" }}
      >
        <div
          className="row justify-content-center"
          style={{ paddingBottom: "2rem" }}
        >
          <div className="section-title text-center">
            <h3 className="pre-title">Még nincs eszköze?</h3>
            <br />
            <span className="title color-dark">Rendeljen tőlünk</span>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-12">
            <div className="inner mt_md--40 mt_sm--40">
              <div className="about-feature-list" style={{ padding: "0 1rem" }}>
                {filteredDevices.map((device, id) => {
                  return (
                    <div
                      key={device.name}
                      className="our-feature device-row sal-animate justify-content-center flex-row"
                      data-sal-delay="150"
                      data-sal="slide-up"
                      data-sal-duration="800"
                    >
                      <div className={"d-flex flex-row"}style={{ display: "flex", alignItems: "center" }}>
                        <img
                            style={{
                              backgroundColor: "#f1b44c9c",
                              padding: ".75rem",
                              borderRadius: "50%",
                              width: "80px",
                              height: "80px",
                            }}
                            src={device.img}
                            alt={device.name}
                        />
                        <div
                          className="feature-content d-flex flex-column"
                          style={{ paddingLeft: "2rem", justifyContent:"flex-start"/* , width: "50rem" */ }}
                        >
                          <h6 style={{paddingBottom:"0"}}>{device.name}</h6>
                          <div
                          className="price price-mobile feature-content d-flex align-items-start flex-column justify-content-center m-0"
                          style={{ padding: "0 1rem" }}
                        >
                          <h6
                            className="feature-title m-0"
                            style={{ whiteSpace: "nowrap" }}
                          >
                          </h6>
                          </div>
                          {device.description && (
                              <p className="feature-description">
                                {device.description}
                              </p>
                          )}
                        </div>
                      </div>

                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Devices;
