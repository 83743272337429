import React, { useContext, useReducer } from "react";
import reducer from "../reducers/happy_reducer.js";

const storedValue = false; /* localStorage.getItem('isLoggedIn') */
const urlParams = new URLSearchParams(window.location.href);
const email = urlParams.get("email");
if (email) {
  localStorage.setItem("email", email);
  localStorage.setItem("isLoggedIn", true);
  window.location.href = window.location.origin;
}
const defaultState = {
  isLoggedIn: email ? true : storedValue ? JSON.parse(storedValue) : false,
};

const HappyContext = React.createContext();

export const HappyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const setIsLoggedIn = (value) => {
    dispatch({ type: "SET_IS_LOGGED_IN", value });
  };

  return (
    <HappyContext.Provider value={{ ...state, setIsLoggedIn }}>
      {children}
    </HappyContext.Provider>
  );
};

export const useHappyContext = () => {
  return useContext(HappyContext);
};
