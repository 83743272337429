import React from "react";
import SEO from "../../common/SEO";

// Create an error boundary
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    window.gtag("event", "exception", {
      description: error,
      fatal: true,
    });
  }

  render() {
    if (this.state.errorInfo) {
      return this.props.fallback;
    }
    return this.props.children;
  }
}

const Error = () => {
  return (
    <ErrorBoundary fallback={<h2>Something went wrong.</h2>}>
      <SEO title="404 Not Found" />
      <div className="edu-error-area eduvibe-404-page edu-error-style" style={{height: "100vh", overflow: "hidden"}}>
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-12">
              <div className="content text-center">
                <div className="thumbnail mb--60">
                  <img src="/images/others/404.png" alt="404 Images" style={{maxHeight: "30rem"}} />
                </div>
                <h3 className="title">
                  Hoppá... A keresett tartalom nem található!
                </h3>
                <p className="description">
                  Kérjük próbáljon meg böngészöje vissza gombjával visszalépni
                  az elözõ oldalra, majd újra felkeresni a kívánt tartalmat.
                </p>
                <div className="backto-home-btn">
                  <a href="/" className="edu-btn">
                    Vissza a kezdőlapra
                    <i className="icon-arrow-right-line-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-11-06.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-09-02.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="/images/shapes/shape-05-06.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-4">
              <img src="/images/shapes/shape-14-03.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-5">
              <img src="/images/shapes/shape-03-10.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-6">
              <img src="/images/shapes/shape-15.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Error;
