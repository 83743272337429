import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../components/sectionTitle/SectionTitle";
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";
import FooterOne from "../common/footer/FooterOne";

const HappyServices = () => {
  const isHomePage = window.location.pathname === "/";
  const data = [
    {
      title: "Weboldal karbantartás: ",
      img: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?q=80&w=3272&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: (
        <>
          <p>
            Havi karbantartás és üzemeltetés, javítás és fejlesztés + ajándék
            tárhely
          </p>
          <ul>
            <li>megbízható szervereink garantálják a honlapod biztonságát</li>
            <li>keresőoptimalizálás elemzést végzünk</li>
            <li>oldalad szakszerű frissítését elvégezzük</li>
            <li>oldalad sebességét ellenőrzés alatt tarjuk</li>
            <li>oldalad megvédjük a támadásoktól és a Spamektől</li>
            <li>biztonsági mentést késztünk</li>
            <li>segítünk a fejlesztésben</li>
            <li>
              a tárhely szolgáltatás díja nem tartozik bele, azt sajátként
              átadjuk ügyfelünk részére
            </li>
            <li>ügyfélszolgálatunkon segítünk, ha bármilyen kérdés felmerül</li>
          </ul>
        </>
      ),
      reversed: false,
    },
    {
      title: "Marketing:",
      img: "https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: (
        <>
          <strong>
            Segítünk a legújabb marketing eszközök használatában. Bemutatjuk
            működését és azok üzleti lehetőségeit. <br />
            Részletesebben:
          </strong>
          <div style={{ paddingTop: "1rem" }}>
            <i>Arculat készítés, grafikai tervezés </i>
            <p>
              A megjelenés lényeges részét képzi minden vizuális marketing
              tevékenységnek. Bármely tartalomhoz használjuk a színeket,
              formákat, grafikai elemeket, azok szép egységként képviselni
              fogják cégünket.
            </p>
            <i>Közösségi média marketing </i>
            <p>
              A közösségi média használatával lehetőség nyílik minél több
              látogató jelenjen meg az ügyfél saját weboldalán. Kampányok és
              egyéb marketing fogás helyszínéül szolgálhat a közösségi médiában
              való megjelenés.
            </p>
            <i>Online hirdetéskezelés </i>
            <p>
              Segítünk az online fizetett hirdetések alkalmazásának
              megtervezésében.{" "}
            </p>
            <i>E-mail marketing </i>
            <p>
              Segítünk a korábbi vásárlók szokásai alapján személyre szóló
              ajánlatot készíteni.{" "}
            </p>
            <i>Webanalitika </i>
            <p>
              Marketing elemzések, analitikák készítése. Célja, hogy
              visszajelzést kapjunk mi ér el kimagasló eredményeket és min kell
              még fejleszteni. Ügyfeleink számára is visszajelzést nyújt, hogy
              milyen előnyei származnak a szolgáltatásaink használatából.
            </p>
          </div>
        </>
      ),
      reversed: true,
    },
    {
      title: "IT: ",
      img: "https://images.unsplash.com/photo-1519389950473-47ba0277781c?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: (
        <>
          <p>
            A különböző vendéglátást és kiskereskedelmet kiszolgáló eszközöket
            és a kapcsolódó szolgáltatásokat ismerve segítünk kiválasztani a
            piacon elérhető legoptimálisabb terméket. Mind az üzletviteli
            folytonosság, a teljesítmény és a biztonsági igények érdekeit
            felsorakoztatva, a legmegfelelőbb megoldást kínáljuk ügyfeleink
            részére.
          </p>
          <ul>
            <li>Informatikai igények pontos felmérése és meghatározása </li>
            <li>A megfelelő eszközök beszerzése</li>
            <li>Hálózat kialakítása, fejlesztése </li>
            <li>Folyamatos rendszergazdai támogatás</li>
          </ul>
        </>
      ),
      reversed: false,
    },
    {
      title: "E-mail:",
      img: "https://images.unsplash.com/photo-1596526131083-e8c633c948d2?q=80&w=3348&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: (
        <>
          <p>
            Szolgáltatásunk a saját honlaphoz kapcsolódó email funkciót
            támogatja.
          </p>
          <ul>
            <li>
              Üzleti email küldésére, vagy fogadásához szükséges címek
              létrehozása{" "}
            </li>
            <li>saját eszközről való elérés, mindenhol ugyanazt látni</li>
            <li>biztonsági mentés</li>
            <li>üzenetek archiválása</li>
            <li>szerver oldali szűrő szabályok létrehozása</li>
          </ul>
        </>
      ),
      reversed: true,
    },
    {
      title: "Telefonközpont: ",
      img: "https://images.unsplash.com/photo-1523966211575-eb4a01e7dd51?q=80&w=3410&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: (
        <>
          <p>
            Telefonos kapcsolattartás, beruházási költség nélkül. Gyorsan
            bevezethető, felhőalapú rendszer mely támogatja az ügyfelek
            megkereséseinek központi telefonszámon való fogadását, a
            kiválasztott melléken pedig a megfelelő embert kapcsolja a rendszer.
          </p>
        </>
      ),
      reversed: false,
    },
    {
      title: "Telepítés kiszállások: ",
      img: "https://images.unsplash.com/photo-1716974228247-91220e62e724?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: (
        <>
          <p>
            Helyszíni kiszállással felmérjük majd az igényeknek megfelelően
            kiépítjük rendszerét. A professzionális telepítés biztosítja, hogy a
            készülék telepítése megfelelően történjen, és az megfelelőképpen
            készen álljon a használatra.
          </p>
        </>
      ),
      reversed: true,
    },
    {
      title: "Online tanácsadás: ",
      img: "https://images.unsplash.com/photo-1516542076529-1ea3854896f2?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: (
        <>
          <p>
            Igényfelmérés, folyamatok és költségek optimalizálása, tanácsadás.
            Manapság kicsit mindenkinek informatikusnak kell lennie ahhoz, hogy
            ki tudja használni a technológia adta lehetőségeket. Segítséget
            nyújtunk az új informatikai megoldások világában, ehhez megfelelő
            rendszerek kialakításában, vagy a már meglévő rendszerek
            átszervezésében.
          </p>
        </>
      ),
      reversed: false,
    },
    {
      title: "Ügyfélszolgálatunk:",
      img: "https://images.unsplash.com/photo-1516055619834-586f8c75d1de?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: (
        <>
          <p>
            Igényfelmérés, folyamatok és költségek optimalizálása, tanácsadás.
            Manapság kicsit mindenkinek informatikusnak kell lennie ahhoz, hogy
            ki tudja használni a technológia adta lehetőségeket. Segítséget
            nyújtunk az új informatikai megoldások világában, ehhez megfelelő
            rendszerek kialakításában, vagy a már meglévő rendszerek
            átszervezésében.
          </p>
        </>
      ),
      reversed: true,
    },
    {
      title: "Újra telepítés",
      img: "https://images.unsplash.com/photo-1716974228247-91220e62e724?q=80&w=3000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: "",
      reversed: false,
    },
    {
      title: "Karbantartás",
      img: "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?q=80&w=3269&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: "",
      reversed: true,
    },
    {
      title: "Adatmigráció",
      img: "https://images.unsplash.com/photo-1558494949-ef010cbdcc31?q=80&w=3434&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      description: "",
      reversed: false,
    },
  ];
  return (
    <>
      {!isHomePage && <SEO title="Szolgáltatások" />}
      <Layout>
        <BreadcrumbOne
          title="Szolgáltatások"
          rootUrl="/"
          parentUrl="Termékeink"
          currentUrl="Szolgáltatások"
        />
        <div
          className="edu-about-area eduvibe-contact-us about-style-3 bg-image happy-services"
          style={{ padding: "4rem 0" }}
        >
          <div className="container eduvibe-animated-shape">
            {data.map((row) => {
              return (
                <div
                  className={`d-flex ${row.reversed ? "reversed" : ""}`}
                  id={row.title}
                  style={{
                    flexDirection: !row.reversed ? "row" : "row-reverse",
                    alignItems: "center",
                    gap: "6rem",
                  }}
                >
                  <div className="inner" style={{ width: "100%" }}>
                    <SectionTitle classes="text-start" title={row.title} />
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOut="fadeInOut"
                      animateOnce={true}
                    >
                      <p className="description mt--40">{row.description}</p>
                    </ScrollAnimation>
                  </div>
                  <div className="inner" style={{ maxWidth: "fit-content" }}>
                    <ScrollAnimation
                      animateIn="fadeIn"
                      animateOut="fadeInOut"
                      className="testimonial-left-image"
                      animateOnce={true}
                      style={{ margin: "5rem 0" }}
                    >
                      <div className="thumbnail">
                        <div
                          className="circle-image-wrapper"
                          style={{ width: "28rem" }}
                        >
                          <img
                            style={{
                              width: "26rem",
                              height: "26rem",
                              objectFit: "cover",
                            }}
                            className="radius-round"
                            src={row.img}
                            alt="Futár modul"
                          />
                          <div className="circle-image">
                            <span></span>
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </ScrollAnimation>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
      {!isHomePage && <FooterOne />}
    </>
  );
};
export default HappyServices;
