import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../components/sectionTitle/SectionTitle";
import FooterOne from "../common/footer/FooterOne";
import SEO from "../common/SEO";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";
import Layout from "../common/Layout";

const PartnerProgram = (props) => {
  const isHomePage = window.location.pathname === "/";
  return (
    <Layout>
      {!isHomePage && <SEO title="Partnerprogram" />}
      {false && (
        <BreadcrumbOne
          style={{
            backgroundImage: `url('https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/delivery-module-banner.jpg')`,
          }}
          title={"<font style='color: white'>Partnerprogram</font>"}
          rootUrl="/"
          parentUrl={"<font style='color: white'>Partnerprogram</font>"}
        />
      )}
      <div className="edu-about-area about-style-3 edu-section-gap bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-12" id={"delivery-module"}>
              <div className="row inner justify-content-center align-items-center d-flex">
                <div className="col-lg-8">
                  <SectionTitle classes="text-start" title={"Partnerprogram"} />

                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}
                  >
                    <p className="description mt--40">
                      Olyan viszonteladói együttműködésre adunk lehetőséget,
                      ahol Partnereink eszközeire, egy stabil jól működő
                      rendszer fejlesztésével és telepítésével vonzó
                      megoldásokat nyújthatunk új és meglévő Ügyfeleink részére.
                      Elkötelezett hívei vagyunk az újdonságok megismerésének.
                      Célunk a megbízható és versenyképes megoldások használata.
                      Olyan szolgáltatást szeretnénk Ügyfeleinknek nyújtani, ami
                      nem csak segíti a mindennapokat, hanem fejlődésük
                      támogatásában is mellettük lesz.
                    </p>
                  </ScrollAnimation>
                </div>
                <div className="col-lg-4 d-flex justify-content-center align-items-start">
                  <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOutUp"
                    className="testimonial-left-image"
                    animateOnce={true}
                  >
                    <div className="thumbnail">
                      <div
                        className="circle-image-wrapper"
                        style={{ width: "25rem" }}
                      >
                        <img
                          style={{
                            width: "23rem",
                            height: "23rem",
                            objectFit: "cover",
                          }}
                          className="radius-round"
                          src="https://images.unsplash.com/photo-1521790797524-b2497295b8a0?q=80&w=3269&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                          alt="Futár modul"
                        />
                        <div className="circle-image">
                          <span></span>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-4">
              <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </div>
      {!isHomePage && <FooterOne />}
    </Layout>
  );
};

export default PartnerProgram;
