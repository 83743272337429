import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../sectionTitle/SectionTitle";
import FooterOne from "../../common/footer/FooterOne";
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import Layout from "../../common/Layout";

const HappyRestaurantModule = (props) => {
  const isHomePage =
    window.location.pathname === "/" ||
    window.location.pathname === "/termekeink/szoftver";
  return (
    <Layout>
      {!props.fromHome && (
        <SEO
          title={"Happy Gastro Éttermi Modul"}
          description={
            "Az étterem vezetése számára bármikor elérhető elemzéseket, kimutatásokat kínál a döntési folyamatot támogatva.Nyomon követhető az össszes aktuális foglalási, forgalmi információ, akár egy mobiltelefonról. Látható ki dolgozik, átvételre került-e a reggel beérkezett szállítmány, milyen státuszban vannak a jelenlegi rendelések a helyszínen vagy a házhozszállítás folyamata épp hol tart, stb ... Minden folyamat és adat részletesen és adott pillanatban, dinamikusan ellenőrizhető.\n"
          }
        />
      )}
      <BreadcrumbOne
        style={{
          backgroundImage: `url('https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/bradcrumb-pc.jpg')`,
        }}
        title={"<font style='color: white'>Happy Gastro Éttermi Modul</font>"}
        rootUrl="/"
        parentUrl={"<font style='color: white'>Termékeink</font>"}
        currentUrl={
          "<font style='color: white'>Happy Gastro Éttermi Modul</font>"
        }
      />
      <div className="edu-about-area about-style-3 edu-section-gap bg-image">
        <div className="container eduvibe-animated-shape">
          <div className="row g-5">
            <div className="col-lg-6" id={"delivery-module"}>
              <div className="inner">
                <SectionTitle
                  classes="text-start"
                  title="Happy Gastro Éttermi Modul"
                />
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                >
                  <p className="description mt--40">
                    A szoftver az étterem igényeinek megfelelően testreszabható,
                    intuitív felülettel rendelkezik, így könnyen elsajátítható a
                    kezelése. A szoftver biztonságosan tárolja az adatokat,
                    jelentéseket készítkészit NTAK bevallásokhoz. (vagy
                    adatszolgáltatáshoz)
                  </p>
                </ScrollAnimation>
                
              </div>
            </div>

            <div className="col-lg-6" id={"delivery-module"}>
              <div className="inner">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        Ingyenes frissítések
                      </h6>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        Gyorsnyugta (pultnál fogyasztás, elvitel)
                      </h6>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        Étlap feltöltés
                      </h6>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        Távoli hozzáférés
                      </h6>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        NTAK adattovábbítás
                      </h6>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        Automatikus blokk nyomtatás
                      </h6>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        Nap zárás részletesen
                      </h6>
                    </div>
                  </div>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  className="col-lg-12 p-0"
                  animateOnce={true}
                >
                  <div className="edu-feature-list">
                    <div className="content">
                      <h6 className="title d-flex align-items-center">
                        <i className="ri-arrow-drop-right-line"></i>
                        Különböző fizetési formák
                      </h6>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>

          <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
            <div className="shape-image shape-image-1">
              <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-2">
              <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-3">
              <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb" />
            </div>
            <div className="shape-image shape-image-4">
              <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb" />
            </div>
          </div>
        </div>
      </div>
      {!isHomePage && <FooterOne />}
    </Layout>
  );
};

export default HappyRestaurantModule;
