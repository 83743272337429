import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../components/sectionTitle/SectionTitle";
import FooterOne from "../common/footer/FooterOne";
import SEO from "../common/SEO";
import BreadcrumbOne from "../common/breadcrumb/BreadcrumbOne";
import Layout from "../common/Layout";

const Career = (props) => {
    const isHomePage = window.location.pathname === "/" || window.location.pathname === "/termekeink/szoftver";
    return (
        <Layout>
            {!isHomePage && <SEO title="Karrier" />}
            {false && <BreadcrumbOne
                style={{backgroundImage: `url('https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/delivery-module-banner.jpg')`}}
                title={"<font style='color: white'>Profilom</font>"}
                rootUrl="/"
                parentUrl={"<font style='color: white'>Profilom</font>"}
            />}
            <div className="edu-about-area about-style-3 edu-section-gap bg-image">
                <div className="container eduvibe-animated-shape">
                    <div className="row g-5">
                        <div className="col-12" id={"delivery-module"}>
                            <div className="row inner justify-content-center align-items-center d-flex">
                                <div className="col-lg-8">
                                    <SectionTitle
                                        classes="text-start"
                                        title={"\"... és képzeld, azért fizetnek, hogy éttermekbe járjak!\""}
                                    />
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOutUp"
                                        className="testimonial-left-text"
                                        animateOnce={true}
                                        delay={300}
                                    >
                                        <div className="title">
                                            Keresed a helyed a vendéglátóipari szoftverek izgalmas világában? Szeretnél
                                            egy
                                            olyan csapat része lenni, ahol a kreativitás és a szókimondás nem csak
                                            elfogadott, de kötelező? Akkor itt a helyed!
                                        </div>
                                    </ScrollAnimation>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center align-items-start">
                                    <ScrollAnimation
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOutUp"
                                        className="testimonial-left-image"
                                        animateOnce={true}
                                    >
                                        <div className="thumbnail">
                                            <div className="circle-image-wrapper" style={{width: "25rem"}}>
                                                <img
                                                    style={{width: '23rem', height: "23rem", objectFit: 'cover'}}
                                                    className="radius-round"
                                                    src="https://images.unsplash.com/photo-1517048676732-d65bc937f952?q=80&w=3270&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                    alt="Futár modul"/>
                                                <div className="circle-image">
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            </div>
                                        </div>
                                    </ScrollAnimation>
                                </div>
                            </div>
                            <div className="row">
                                <ScrollAnimation
                                    animateIn="fadeInUp"
                                    animateOut="fadeInOut"
                                    animateOnce={true}
                                >
                                    <p className="description mt--40">
                                        Kit keresünk?

                                        Kreatív gondolkodókat, akik nem riadnak vissza egy kis agytornától.
                                        Ügyes beszélőket, akik képesek eladni egy víziót, mégpedig úgy, hogy az
                                        valósággá váljon.
                                        Kereskedői véna birtokosait, akik a 'nem' választ is képesek 'igen'-re
                                        fordítani.
                                        Vadász típusú munkatársakat, akik nem ismernek lehetetlent, ha üzletről van szó.

                                        Mit kínálunk?

                                        Egy dinamikus, fiatalos csapatot, ahol a siker a közös munka gyümölcse.
                                        Versenyképes juttatásokat, mert tudjuk, hogy a tehetségnek ára van.
                                        Folyamatos fejlődési lehetőséget, hiszen nálunk a tanulás sosem ér véget.

                                        Ha úgy érzed, a leírás rád illik, és szeretnél egy olyan helyen dolgozni, ahol
                                        minden nap izgalmas kihívások várnak, akkor ne habozz! Küldd el önéletrajzodat,
                                        és találjuk meg együtt a siker receptjét!


                                        Találd meg a szenvedélyt, és végezz olyan munkát, amiben sikereket érsz el és
                                        örömet okoz neked.

                                        Mellékállásban és vállalkozói viszonyban végezhető munka, nem csak anyagi
                                        függetlenséget érsz el egy piacképes juttatási csomaggal, de teljes mértékben a
                                        magad ura lehetsz.

                                        Ne félj belevágni!

                                        Várjuk jelentkezésedet, hogy együtt írjuk tovább a vendéglátóipar jövőjét!
                                        <br/> <br/>
                                        Küldj e-mailben egy rövid bemutatkozót és csatolj hozzá egy önéletrajzot, hogy
                                        megismerhessük a készségeidet és tapasztalataidat.

                                        <br/>

                                        A Happy Gastro csapata: <a href="mailto:sales@happygastro.hu">sales@happygastro.hu</a>
                                    </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                        <div className="shape-image shape-image-1">
                            <img src="/images/shapes/shape-21.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-2">
                            <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-3">
                            <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb"/>
                        </div>
                        <div className="shape-image shape-image-4">
                            <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb"/>
                        </div>
                    </div>
                </div>
            </div>
            {!isHomePage && <FooterOne/>}
        </Layout>
    );
};

export default Career;
