import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import TestimonialTwo from '../testimonial/TestimonialTwo';
import SectionTitle from '../sectionTitle/SectionTitle';

const TestimonialSectionOne = () => {
    return (
        <div className="eduvibe-home-four-testimonial edu-testimonial-area edu-section-gap bg-color-white">
            <div className="container eduvibe-animated-shape">
                <div className="row">
                    <div className="col-lg-6">
                        <ScrollAnimation
                            animateIn="fadeIn"
                            animateOut="fadeInOut"
                            className="testimonial-left-image pr--80"
                            animateOnce={ true }
                        >
                            <div className="thumbnail">
                                <div className="circle-image-wrapper">
                                    <img className="radius-round" src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/images/home-mobile-circle.png" alt="Happy POS™ Mobile app" />
                                    <div className="circle-image">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </ScrollAnimation>
                    </div>
                    <div className="col-lg-6">
                        <div className="testimonial-wrapper">
                            <SectionTitle
                                classes = "text-start mb--40"
                                slogan = "Happy POS&reg;"
                                title = "Mobil alkalmazás, ahogy még soha"
                            />
                        </div>
                        <p>
                            Szoftverünk kézi pincér terminál modulja lehetővé teszi, hogy valamilyen mobil eszköz használatával tudjunk rendelést felvenni már közvetlenül a vendégasztalnál, így a rendelés automatikusan eljut a konyha, illetve a pult felé a blokknyomtatón keresztül. A működés <b>Apple</b> és <b>Android</b> eszközökön is gördülékeny , melyen keresztül a rendelésfelvétel tökéletesen leadható.
                        </p>
                        <h5 style={{fontSize: '2.1rem', textAlign: 'justify'}}>Legyen szó <b>saját eszközéről</b> vagy <b>Happy POS&reg; terminálról</b>, mobil szoftverünk önálló eszközként is kiváló választás!</h5>
                        <div className="row">
                            <div className={'col-12 col-md-6'}>
                                <span>
                                    Happy POS letöltés
                                </span>
                                <div style={{marginTop: '.75rem'}}>
                                    <a href="https://play.google.com/store/apps/details?id=hu.happygastro.restaurant" target='_blank'>
                                        <img style={{maxWidth: '22rem'}} src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/Google_Play_2022_logo.svg.png" alt="https://play.google.com/store/apps/details?id=hu.happygastro.restaurant"/>
                                    </a>
                                </div>
                            </div>
                            <div className={'col-12 col-md-6'}>
                                <span>
                                    Happy Management letöltés
                                </span>
                                <div style={{marginTop: '.75rem'}}>
                                    <a href="https://play.google.com/store/apps/details?id=hu.happygastro.management" target='_blank'>
                                        <img style={{maxWidth: '22rem'}} src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/Google_Play_2022_logo.svg.png" alt="https://play.google.com/store/apps/details?id=hu.happygastro.management"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonialSectionOne;
