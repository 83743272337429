import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SEO from '../../common/SEO';
import Layout from '../../common/Layout';
import BreadcrumbOne from '../../common/breadcrumb/BreadcrumbOne';
import EventData from '../../data/event/EventData.json';
import * as maniAnimation from "../../assets/animations/main.json";


import Lottie from "react-lottie";
import HomeFourAbout from "../../components/home-four/HomeFourAbout";
import TestimonialSectionOne from "../../components/testimonial-section/TestimonialSectionOne";
import FooterOne from "../../common/footer/FooterOne";

const HappyPOS = (props) => {
    const { id } = useParams();
    const eventId = parseInt(id, 10);
    const data = EventData.filter(event => event.id === eventId);
    const isHomePage = window.location.pathname === "/" || window.location.pathname === "/termekeink/szoftver";
    return (
        <>
            {!props.fromHome && <SEO title={ "Mobil pincér app" } description={"Mobil felszolgáló appunkkal most még egyszerűbb a munka!"}/>}
            <Layout>
                <BreadcrumbOne
                    style={{backgroundImage: `url('https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/HappyWeb/mobile-breadcrumb-bg.jpg')`}}
                    title={"<font style='color: white'>Mobil pincér app</font>"}
                    rootUrl="/"
                    parentUrl={"<font style='color: white'>Termékeink</font>"}
                    currentUrl={"<font style='color: white'>Mobil pincér app</font>"}
                />
                <div className="mobile-pos edu-event-details-area edu-event-details edu-section-gap bg-color-white">

                    <div className="container" style={{width: '100%', paddingLeft: 0, paddingRight: 0}}>
                        <TestimonialSectionOne />
                    </div>

                    <div className="container">
                        <div className="row g-5">



                            <div className="col-lg-7">


                                <div className="content" style={{marginTop: 0}}>
                                    <h4 className="title" style={{fontSize: 30}}>még sok más, amire képesek vagyunk</h4>
                                    <ul>
                                        <li style={{fontSize: 17}}>Valós idejű forgalmi adatok bármilyen eszközről, bárhonnan*</li>
                                        <li style={{fontSize: 17}}>Készletkezelés valós idejű alapanyag fogyással a felütés pillanatában</li>
                                        <li style={{fontSize: 17}}>Szerkeszthető értesítések minden lényeges dologról, legyen az egy esemény (pl. ültetés, törlés, számla storno), forgalmi vagy készlet információ változásról</li>
                                        <li style={{fontSize: 17}}><a className={'text-primary'} href={'/termekeink/happy-pos'} target={'_blank'} rel="noreferrer">Mobil rendelésfelvétel</a>, többféle érintésmentes fizetéssel</li>
                                        <li style={{fontSize: 17}}>Pénztárgép és adóügyi nyomtató integráció</li>
                                        <li style={{fontSize: 17}}>Megfelel az <a className={'text-primary'} href={'https://ntak.hu/'} target={'_blank'} rel="noreferrer">NTAK adatszolgáltatási</a> kötelezettségnek</li>
                                    </ul>
                                </div>



                                <div className="content" style={{marginTop: 0}}>
                                    <h4 className="title" style={{fontSize: 30}}>és még mindig nincs vége</h4>
                                    <ul>
                                        <li style={{fontSize: 17}}>Csoportos elrendezés a jobb átláthatóságért</li>
                                        <li style={{fontSize: 17}}>Inteligens termék kereső</li>
                                        <li style={{fontSize: 17}}>Átlátható felütött tételek lista, gyors tételszám és kedvezmény/extra módosítás</li>
                                        <li style={{fontSize: 17}}>Kedvezmény, törzsvendég gyors hozzáadása</li>
                                        <li style={{fontSize: 17}}>Elvitel-helyben fogyasztás gyorskapcsoló a változó áfa tartalom miatt</li>
                                        <li style={{fontSize: 17}}>Fogások, körök beállítása a konyha számára, következő fogás kérése</li>
                                        <li style={{fontSize: 17}}>Átültetés asztalok között, számla bontási lehetőség</li>
                                    </ul>
                                </div>


                            </div>

                            <div className="col-lg-5">
                                <div className="eduvibe-sidebar" style={{marginTop: -100}}>
                                    <div className="eduvibe-widget eduvibe-widget-details mt--60">
                                        <h5 className="title text-center">*Valós idejű adatszinkron</h5>
                                        <div className="widget-content">
                                            <Lottie options={{
                                                loop: true,
                                                autoplay: true,
                                                animationData: maniAnimation,
                                                rendererSettings: {
                                                    preserveAspectRatio: 'xMidYMid cover'
                                                }
                                            }} width={"300px"} height={"300px"}/>
                                            <p style={{textAlign: 'justify', marginBottom: -20}}>Rendszereink a felhõnek köszönhetöen folyamatosan, valós idõben szinkronizálnak így akár azt is láthatja, ha kollégája éppen másik rendelést szolgál ki</p>
                                        </div>
                                    </div>

                                    <div className="event-widget event-widget-details mt--40">
                                        <h5 className="title text-center">Bármely rendszeren*</h5>
                                        <div className="row justify-content-center align-items-center" style={{margin: '0 auto', flexWrap: 'nowrap'}}>
                                            <div className="col widget-content">
                                                <img style={{height: '6rem', aspectRatio: '1/1', objectFit: 'contain'}} src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/Windows_logo.svg.png" alt="Windows"/>
                                                {false && <img src={require('../../assets/images/operating-systems.jpg')}
                                                               alt="Éttermmi szoftverünk minden platformon megállja a helyét!"/>}
                                                {false&&<div className="read-more-btn mt--15">
                                                    <Link className="edu-btn w-100 text-center" to="#">Töltse le
                                                        most!</Link>
                                                </div>}
                                            </div>
                                            <div className="col widget-content">
                                                <img style={{height: '6rem', aspectRatio: '1/1', objectFit: 'contain'}} src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/Apple_logo_black.svg.png" alt="Apple"/>
                                            </div>
                                            <div className="col widget-content">
                                                <img style={{height: '6rem', aspectRatio: '1/1', objectFit: 'contain'}} src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/Android_robot.svg.webp" alt="Android"/>
                                            </div>
                                            <div className="col widget-content">
                                                <img style={{height: '7rem', aspectRatio: '1/1', objectFit: 'contain'}} src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/linux.png" alt="Linux"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {!isHomePage && <FooterOne/>}
            </Layout>
        </>
    )
}
export default HappyPOS;
