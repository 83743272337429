import React, {useState} from "react";
import SEO from "../../src/common/SEO";
import Layout from "../../src/common/Layout";
import FooterOne from "../common/footer/FooterOne";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Table,
  TableBody,
  td,
  TableContainer,
  TableHead,
  tr,
  Paper,
} from "@mui/material";
import axios from "axios";

const ModulePrices = () => {
  const data = [
    {
      feature: "1 gépen fut a program",
      miniPackage: "9 900 Ft",
      basicPackage: "15 000 Ft",
      premiumPackage: "20 000 Ft",
      extraModule: "",
    },
    {
      feature: "Étlap feltöltése",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Távoli hozzáférés",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "NTAK adattovábbítás",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Automatikus blokk nyomtatás",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Napi zárás részletesen",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Munkatársak száma",
      miniPackage: "max 3",
      basicPackage: "max 8",
      premiumPackage: "korlátlan",
      extraModule:
        /*"Itt lehetnee, hogy minden további munkatárs +990 Ft peldaul",*/ "",
    },
    {
      feature: "Jogosultság kezelése",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Gyors kiszolgálás",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Asztalos rendelések/felütések",
      miniPackage: false,
      basicPackage: "választható",
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Házhozszállítás",
      miniPackage: false,
      basicPackage: "választható",
      premiumPackage: true,
      extraModule: "",
    },
    {
      feature: "Ügyféladatbázis + VIP",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: true,
      extraModule: "2 000 Ft",
    },
    {
      feature: "Asztalfoglalás modul",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "5 000 Ft",
    },
    {
      feature: "Happy mobil pincér készülékenként",
      miniPackage: true,
      basicPackage: true,
      premiumPackage: true,
      extraModule: "5 000 Ft",
    },
    {
      feature: "Készlet / raktár modul",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: true,
      extraModule: "6 900 Ft",
    },
    {
      feature: "Integrációk - Wolt, Woocommerce stb",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: true,
      extraModule: "5 000 Ft ",
    },
    {
      feature: "Manager / Tulaj statisztikák Modul",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: true,
      extraModule: "2 000 Ft",
    },
  ];

  const extraModules = [
    {
      feature: "Digitális étlap",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "2 000 Ft",
    },
    {
      feature: "Kiosk",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "2 000 Ft",
    },
    {
      feature: "Vevősorszám kijelzés",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "2 000 Ft",
    },
    {
      feature: "Futár modul",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "5 000 Ft",
    },
    {
      feature: "Diszpécser modul gépenként",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "5 000 Ft",
    },
    {
      feature: "Gyártás modul",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "6 900 Ft",
    },
    {
      feature: "Számlázás modul",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "2 000 Ft",
    },
    {
      feature: "Pénztárgép modul kasszánként",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "2 500 Ft",
    },
    {
      feature: "Happy API fejlesztők modul",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "2 000 Ft",
    },
    {
      feature: "Beszerzési modul",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "5 000 Ft",
    },
    {
      feature: "Franchise modul",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "20 000 Ft",
    },
    {
      feature: "Weboldal karbantartás",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "30 000 Ft",
    },
    {
      feature: "Marketing",
      miniPackage: "",
      basicPackage: "",
      premiumPackage: "",
      extraModule: "30 000 Ft",
    },
    {
      feature: "IT email, tárhelye",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "5000 Ft",
    },
    {
      feature: "Telefon központ",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "5000 Ft",
    },
    {
      feature: "KDS",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "6 000 Ft",
    },
    {
      feature: "Pénzügyi modul",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "5 000 Ft",
    },
    {
      feature: "Saját applikáció",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "30 000 Ft",
    },
    {
      feature: "Telepítés / kiszállás Budapest",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "39 900 Ft",
    },
    {
      feature: "Pestmegye",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "49 900 Ft",
    },
    {
      feature: "Online tanácsadás",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "10 000 Ft",
    },
    {
      feature: "Újratelepítés / karbantartás",
      miniPackage: false,
      basicPackage: false,
      premiumPackage: false,
      extraModule: "30 000 Ft",
    },
  ];
  const [scrollY, setScrollY] = React.useState(0);
  React.useEffect(() => {
    const scroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", scroll);
    return () => {
      window.removeEventListener("scroll", scroll);
    };
  }, []);

  const [responseBody, setResponseBody] = useState(null);
  const [modules, setModules] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [moduleTableContent, setModuleTableContent] = React.useState(null);

  const api = axios.create({
    baseURL: 'https://api.happygastro.hu',
    headers: {
      'Content-Type': 'application/json',
    },
  });


  const fetchBillingPlans = async () => {
    console.log("fetch")

    try {
      const response = await api.get('/api/billing/plans');
      console.log(response.status)
      console.log(response.data)
      setResponseBody(response.data)

      if (response.status === 200) {
        const { plans, licenses } = response.data;
        let modulesToPush = []
        let servicesToPush = []

        licenses.map((license) => {
              if(license.type === 'service'){
                servicesToPush = [...servicesToPush, license]
              }else{
                modulesToPush = [...modulesToPush, license]
              }
            }
        )
        setModules([...modules, ...modulesToPush])
        setServices([...services, ...servicesToPush])
      } else {
        console.error('Unexpected response format:', response);
      }
    } catch (error) {
      console.error('Error fetching billing plans:', error);
    }
  };

  React.useEffect(() => {
    fetchBillingPlans();
  }, []);

  // feature: "Étlap feltöltése",
  //       miniPackage: true,
  //       basicPackage: true,
  //       premiumPackage: true,
  //       extraModule: "",

  React.useEffect(() => {
    if(modules){
      let newModuleTableContent = [];
      modules.map((module, index) => {
        if(index === 0){
          newModuleTableContent.push(
          {
            feature: "+1 POS terminál használati díja",
            miniPackage: "9 900 Ft",
            basicPackage: "14 900 Ft",
            premiumPackage: "19 990 Ft",
            extraModule: "",
          })
        } else{
          if(module.prices.minimal === 0){
            newModuleTableContent.push(
                {feature: module.name,
                  miniPackage: module.includedIn.includes("minimal"),
                  basicPackage: module.includedIn.includes("normal"),
                  premiumPackage: module.includedIn.includes("premium"),
                  extraModule: ""}
            )
          }else{
            newModuleTableContent.push(
                {
                  feature: module.name,
                  miniPackage: module.includedIn.includes("minimal"),
                  basicPackage: module.includedIn.includes("normal"),
                  premiumPackage: module.includedIn.includes("premium"),
                  extraModule: module.prices.minimal + " Ft"}
            );
          }
        }
      });
      setModuleTableContent(newModuleTableContent);
    }


  }, [modules]);





  return (
    <div style={{ position: "relative" }} className={"module-prices-wrapper"}>
      <SEO
        title="Árajánlat"
        description={
          "Csomagjaink, moduljaink és eszközeink árai - árajánlat kérése, demó igénylése"
        }
      />
      <Layout>
        <div
          className={
            "edu-about-area about-style-3  bg-image eduvibe-contact-us"
          }
          style={{ padding: "8rem 0" }}
        >
          <div
            className={"table-wrapper eduvibe-animated-shape "}
            style={{ maxWidth: 850, margin: "0 auto" }}
          >
            <table className={"module-prices-table"} aria-label="simple table">
              <thead className={`head ${scrollY > 200 ? "sticky" : ""}`}>
                <tr>
                  <th></th>
                  <th align="right">Mini csomag</th>
                  <th align="right">Alap csomag</th>
                  <th align="right">Prémium csomag</th>
                  <th align="right">Extra modulként / hó</th>
                </tr>
              </thead>
              <tbody>
                {moduleTableContent?.map((row, index) => (
                  <tr key={index}>
                    <td scope="row">{row.feature}</td>
                    <td align="right">
                      {typeof row.miniPackage === "boolean" ? (
                        row.miniPackage ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )
                      ) : (
                        row.miniPackage
                      )}
                    </td>
                    <td align="right">
                      {typeof row.basicPackage === "boolean" ? (
                        row.basicPackage ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )
                      ) : (
                        row.basicPackage
                      )}
                    </td>
                    <td align="right">
                      {typeof row.premiumPackage === "boolean" ? (
                        row.premiumPackage ? (
                          <CheckIcon />
                        ) : (
                          <CloseIcon />
                        )
                      ) : (
                        row.premiumPackage
                      )}
                    </td>
                    <td align="right">{row.extraModule}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {false && (
              <div
                className="extras"
                style={{ marginTop: "4rem", padding: "4rem 2rem" }}
              >
                <h3 style={{ textAlign: "center" }}>
                  További modulok és plusz szolgáltatások
                </h3>
                <div
                  className="extras-list"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    paddingTop: "2rem",
                  }}
                >
                  {extraModules.map((row, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "2rem",
                        marginBottom: "1rem",
                        flexBasis: "50%",
                        padding: "0 1rem",
                      }}
                    >
                      <strong style={{ fontSize: "1.85rem" }}>
                        {row.feature}
                      </strong>
                      <div
                        style={{
                          flexGrow: 1,
                          borderBottom: "1px dotted",
                        }}
                      ></div>
                      <span align="right">{row.extraModule}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
              <div className="shape-image shape-image-1">
                <img src="/images/shapes/shape-21.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-2">
                <img src="/images/shapes/shape-13-04.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-3">
                <img src="/images/shapes/shape-03-05.png" alt="Shape Thumb" />
              </div>
              <div className="shape-image shape-image-4">
                <img src="/images/shapes/shape-15-02.png" alt="Shape Thumb" />
              </div>
            </div>
          </div>
        </div>
        <FooterOne />
      </Layout>
    </div>
  );
};
export default ModulePrices;
