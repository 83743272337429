import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerOne from '../../components/banner/BannerOne';
import NewsLetterFormTwo from '../../components/newsletter/NewsLetterTwo';
import DeliveryModule from "../../components/about/AboutFour";
import HappyGastro from "../detailspages/HappyGastro";
import HappyPOS from "../detailspages/HappyPOS";
import ContactUs from "../innerpages/ContactUs";
import Pricing from "../innerpages/Pricing";
import WhyChooseUs from "../../components/home-two/HomeTwoService";
import Integrations from "../../components/banner/integrations";
import FooterOne from "../../common/footer/FooterOne";
import HappyHardwares from "../../innerpages/HappyHardwares";
import HappySoftwares from "../../innerpages/HappySoftwares";
import HappyServices from "../../innerpages/HappyServices";
import DemoBanner from "../../components/banner/DemoBanner";
import ZoomableImage from "../../components/banner/ZoomableImage";

const HomeThree = () => {
    const [isBannerFullScreen, setIsBannerFullScreen] = React.useState(false);

    return (
        <>
            {!isBannerFullScreen ? <><SEO title='Kezdőlap'/>
                <HeaderTwo styles="header-transparent header-style-1" fromHome={true}/>
    <BannerOne fromHome={true} isBannerFullScreen={isBannerFullScreen} setIsBannerFullScreen={setIsBannerFullScreen}/>
    <WhyChooseUs fromHome={true}/>
    <DemoBanner/>
    <HappySoftwares/>
    {false && <HappyGastro fromHome={true}/>}
    {false && <HappyPOS fromHome={true}/>}
    {false && <DeliveryModule fromHome={true}/>}
    <HappyHardwares fromHome={true}/>
    {/*Kell még a happyManagement*/}
    {false && <Pricing/>}
    <HappyServices fromHome={true}/>
    <ContactUs fromHome={true}/>
    <Integrations fromHome={true}/>

    <NewsLetterFormTwo fromHome={true}/>

    <FooterOne/></> : <ZoomableImage isBannerFullScreen={isBannerFullScreen} setIsBannerFullScreen={setIsBannerFullScreen}/>
            }


        </>
    )
}

export default HomeThree;
