import React from 'react';
import {Link as ScrollTo} from 'react-scroll';
import ZoomableImage from "./ZoomableImage";

const BannerOne = (props) => {


    return (
        <div className="slider-area banner-style-1 bg-image  d-flex align-items-center" style={{paddingTop: 120}}>
            <div className="container eduvibe-animated-shape" style={{
                margin: '0 auto', width: '100vw', maxWidth: '100vw', padding: 0
            }}>
                <div className="row g-5 row--40 align-items-center" style={{margin: 0}}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        {false && <div className="banner-right-content"
                                       style={{display: 'flex', justifyContent: 'flex-start'}}>
                            <div className="content">
                                <h1 className="title">Rólunk részletesebben</h1>
                            </div>
                        </div>}
                        <div className="placeholder">
                        </div>
                            <ZoomableImage isBannerFullScreen={props.isBannerFullScreen} setIsBannerFullScreen={props.setIsBannerFullScreen}/>
                        </div>
                    </div>

                    <div className="shape-dot-wrapper shape-wrapper d-xl-block d-none">
                    <div className="shape shape-1">
                        <img src="/images/shapes/shape-01.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-2">
                        <img src="/images/shapes/shape-02.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-5">
                        <img src="/images/shapes/shape-05.png" alt="Shape Thumb" />
                    </div>
                    <div className="shape shape-6">
                        <img src="/images/shapes/shape-05-05.png" alt="Shape Thumb" />
                    </div>
                </div>

                <div className="shape-round">
                    <img src="/images/banner/banner-01/shape-27.png" alt="Shape Images" />
                </div>
            </div>
            
            {false && <div className="scroll-down-btn">
                <ScrollTo
                    className="round-btn"
                    to="about-us"
                    spy={true}
                    smooth={true}
                    duration={200}
                >
                    <i className="icon-arrow-down-s-line"></i>
                </ScrollTo>
            </div>}
        </div>

    )
}
export default BannerOne;
